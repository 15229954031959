<template>
  <v-container class="receipt-details">
    <div class="py-2 pr-1 d-flex align-center">
      <div class="flex-grow-1">
        <div
          v-if="receipt.dataSpesa"
          class="text-body-0 font-weight-semibold text-uppercase"
        >
          {{ $dayjs(receipt.dataSpesa, "YYYYMMDDHHmmss").format("DD MMMM") }}
        </div>
      </div>
      <div class="flex-shrink-0">
        <v-btn icon class="mr-2" @click="share">
          <v-icon color="default">$share</v-icon>
        </v-btn>
        <v-btn icon
          ><v-icon
            color="default"
            @click="
              handleReceiptDownload(
                receipt.pdf,
                'Scontrini_' + receipt.dataSpesa
              )
            "
            >$download</v-icon
          ></v-btn
        >
      </div>
    </div>
    <img
      :src="receipt.png"
      :alt="receipt.dataSpesa"
      class="receipt-img"
      onerror="this.onerror=null;this.src='/no-icon.png'"
    />
  </v-container>
</template>
<style lang="scss">
.receipt-details {
  .receipt-img {
    width: 100%;
    border: 2px solid var(--v-grey-base);
  }
}
</style>
<script>
import CustomService from "@/service/customService";
import downloadDocumentMixin from "~/mixins/downloadDocument";
import socialsharing from "@/mixins/socialsharing";
export default {
  name: "ReceiptDetails",
  mixins: [downloadDocumentMixin, socialsharing],
  data() {
    return {
      receipt: {}
    };
  },
  methods: {
    async reload(index) {
      await CustomService.getReceiptPdf(index).then(response => {
        this.receipt = response.value;
      });
    },
    async share() {
      const pdfUrl = encodeURI(window.location.origin + this.receipt.pdf);
      const fileName =
        "Scontrini " +
        this.$dayjs(this.receipt.dataSpesa, "YYYYMMDDHHmmss").format(
          "DD/MMMM/YYYY"
        );

      try {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
          const base64data = reader.result;

          window.plugins.socialsharing.share(null, fileName, base64data, null);
        };
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  },
  created() {
    this.reload(this.$route.params.index);
  }
};
</script>
