var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "receipt-details" }, [
    _c("div", { staticClass: "py-2 pr-1 d-flex align-center" }, [
      _c("div", { staticClass: "flex-grow-1" }, [
        _vm.receipt.dataSpesa
          ? _c(
              "div",
              {
                staticClass: "text-body-0 font-weight-semibold text-uppercase"
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm
                        .$dayjs(_vm.receipt.dataSpesa, "YYYYMMDDHHmmss")
                        .format("DD MMMM")
                    ) +
                    " "
                )
              ]
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "flex-shrink-0" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: { icon: "" },
              on: { click: _vm.share }
            },
            [_c("v-icon", { attrs: { color: "default" } }, [_vm._v("$share")])],
            1
          ),
          _c(
            "v-btn",
            { attrs: { icon: "" } },
            [
              _c(
                "v-icon",
                {
                  attrs: { color: "default" },
                  on: {
                    click: function($event) {
                      return _vm.handleReceiptDownload(
                        _vm.receipt.pdf,
                        "Scontrini_" + _vm.receipt.dataSpesa
                      )
                    }
                  }
                },
                [_vm._v("$download")]
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("img", {
      staticClass: "receipt-img",
      attrs: {
        src: _vm.receipt.png,
        alt: _vm.receipt.dataSpesa,
        onerror: "this.onerror=null;this.src='/no-icon.png'"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }